import React, { useState } from 'react'
import {
	Box,
	HStack,
	VStack,
	Icon,
	Text,
	Heading,
	Divider,
	SimpleGrid,
	Tooltip,
} from '@chakra-ui/react'
import DeleteDialog from '../../../components/delete-dialog'
import CreateRenameModal from '../../../components/CreateRenameModal'
import Card from '../../../components/card'
import SharedModal from './shared-view'
import Folder from './folders'
import {
	FOLDER_TYPE,
	DOCS_ACCESS_TYPES,
	createDocumentPath,
	// sectionDetailedTrack,
	// GA_CATEGORY_REPOSITORY,
	// dataLayerTagManager,
} from '../repo.utils'
import { FaFolderPlus } from 'react-icons/fa'
import {
	useRemoveDocument,
	useCreateFolder,
	useRenameFolder,
} from '../../../hooks/documents.hooks'
import { getLocalAuthDetails } from '../../../utils/common.util'
import { sharedConst } from '../../../utils/action.constant'
import { useAuthContext } from '../../../context/auth.context'

export const FolderView = ({
	accessableRepositories,
	docTypes,
	title,
	icon,
	onSelect,
	refresh,
	folderType,
	breadCrumbPath,
	access,
	isSearch,
}) => {
	const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
	const [isOpenEditModal, setIsOpenEditModal] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [isOpenShareModal, setIsOpenShareModal] = useState(false)
	const [documentSelected, setDocumentSelected] = useState('')
	const { mutate: removeDocumentMutate } = useRemoveDocument()
	const { mutate: createFolderMutate } = useCreateFolder()
	const { mutate: renameFolderMutate } = useRenameFolder()

	const {
		state: { authData },
	} = useAuthContext()

	const enableAccess = [
		// DOCS_ACCESS_TYPES.ADMIN,
		DOCS_ACCESS_TYPES.SUPER_ADMIN,
	].includes(access)

	const deleteDocument = payload => {
		removeDocumentMutate(payload, {
			onSuccess: async res => {
				const {
					data: { status },
				} = res
				if (status === 200) {
					refresh()
				}
			},
			onError: async err => {
				console.log(err)
			},
		})
	}
	const removeDocument = document => {
		const { document_repository_id } = documentSelected
		if (!!isOpen) {
			setIsOpen(false)
			deleteDocument({ document_repository_id: document_repository_id })
		}
	}

	const onCreateNewFolder = docname => {
		const { activity_type_id, activity_type_name } =
			breadCrumbPath[breadCrumbPath.length - 1].value
		const userData = getLocalAuthDetails()
		const path = `${userData.organization_id}/${createDocumentPath(
			breadCrumbPath
		)}${docname.toLowerCase().split(' ').join('_')}/`

		let params = {
			document_repository_name: docname,
			document_repository_sub_type_id: 0,
			document_repository_sub_type_name: 'Folder',
			document_repository_folder_url: JSON.stringify([
				{ name: docname, url: path },
			]),
			parent_document_repository_id:
				breadCrumbPath.length - 1 === 0
					? 0
					: breadCrumbPath[breadCrumbPath.length - 1].value
							.document_repository_id,
			tag_id: 0,
			activity_type_id,
			activity_type_name,
		}
		createFolderMutate(params, {
			onSuccess: async res => {
				const {
					data: { status },
				} = res
				if (status === 200) {
					refresh()
				}
			},
			onError: async err => {
				console.log(err)
			},
		})
		onCloseModal()
	}
	const onDelete = docs => {
		setDocumentSelected(docs)
		setIsOpen(true)
	}
	const openRenameModal = docs => {
		setDocumentSelected(docs)
		setIsOpenEditModal(true)
	}
	const onRename = docname => {
		if (documentSelected.document_repository_name !== docname) {
			const { document_repository_id } = documentSelected
			const params = {
				document_repository_name: docname,
				document_repository_id: document_repository_id,
			}
			renameFolderMutate(params, {
				onSuccess: async res => {
					const {
						data: { status },
					} = res
					if (status === 200) {
						refresh()
					}
				},
				onError: async err => {
					console.log(err)
				},
			})
		}
		onCloseModal()
	}
	const onShare = docs => {
		setDocumentSelected(docs)
		setIsOpenShareModal(true)
	}
	const onCloseModal = () => {
		setIsOpenCreateModal(false)
		setIsOpenEditModal(false)
		setIsOpen(false)
		setIsOpenShareModal(false)
		setDocumentSelected('')
	}
	const isShowNewFolder =
		folderType !== FOLDER_TYPE.SHARED &&
		!isSearch &&
		!!breadCrumbPath &&
		((breadCrumbPath.length === 1 &&
			[1, 2].includes(breadCrumbPath[0].value.activity_type_access_level_id)) ||
			(breadCrumbPath.length > 1 &&
				(breadCrumbPath[1].value.asset_participant_access_id === 2 ||
					enableAccess ||
					authData?.asset_id === breadCrumbPath[1].value.creator_asset_id ||
					breadCrumbPath[1].value.activity_creator_asset_id) &&
				breadCrumbPath.length <= 2))
			? true
			: false

	let access_check =
		!!breadCrumbPath &&
		!!breadCrumbPath.length &&
		!!breadCrumbPath[breadCrumbPath.length - 1] &&
		!!breadCrumbPath[breadCrumbPath.length - 1].value &&
		!!breadCrumbPath[breadCrumbPath.length - 1].value
			.asset_participant_access_name
			? breadCrumbPath[
					breadCrumbPath.length - 1
			  ].value.asset_participant_access_name.toUpperCase()
			: access

	return (
		<>
			<Box p={2}>
				{docTypes.length > 0 || (!isSearch && !!breadCrumbPath) ? (
					<>
						<Heading fontSize='md' fontWeight='500' textTransform='uppercase'>
							{title}
						</Heading>
						<Divider my={2} />
					</>
				) : null}
				<SimpleGrid spacing={2} columns={4} w='full'>
					{docTypes.map((folderData, index) => {
						// console.log(folderData)
						return (
							<Folder
								key={Math.random() * Date.now()}
								folderData={folderData}
								onSelect={onSelect}
								onDelete={onDelete}
								onRename={openRenameModal}
								onShare={onShare}
								icon={icon}
								isSearch={isSearch}
								breadCrumbPath={breadCrumbPath}
								access={
									access === DOCS_ACCESS_TYPES.SUPER_ADMIN
										? access
										: authData?.asset_id ===
										  (folderData.creator_asset_id ||
												folderData.activity_creator_asset_id)
										? 'ADMIN'
										: !!folderData.asset_participant_access_name
										? folderData.asset_participant_access_name.toUpperCase()
										: access_check
								}
							/>
						)
					})}
					{!isSearch && !!breadCrumbPath ? (
						<Tooltip
							isDisabled={isShowNewFolder}
							label="You don't have access!"
						>
							<HStack
								opacity={!isShowNewFolder ? 0.4 : 1}
								key={-1}
								alignItems='center'
								justifyContent='center'
								cursor='pointer'
								my={2}
								borderRadius='md'
								boxShadow='md'
								maxW='250px'
								w='full'
								p={2}
								onClick={() => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_REPOSITORY,
									// 	action: 'Folder View',
									// 	label: 'Open Rename Modal',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Document Repository',
									// 	buttonName: 'New Folder',
									// })
									isShowNewFolder && setIsOpenCreateModal(true)
								}}
							>
								<VStack justifyContent='center'>
									<Box
										rounded='md'
										size='md'
										color={localStorage.getItem('color')}
										bg='secondary'
										p={2}
									>
										<Icon as={FaFolderPlus} h={6} w={6} />
									</Box>
								</VStack>
								<VStack
									alignItems='flex-start'
									justifyContent='flex-start'
									w='full'
								>
									<Text fontWeight={500} my={0} fontSize='md'>
										New Folder
									</Text>
								</VStack>
							</HStack>
						</Tooltip>
					) : null}
				</SimpleGrid>
			</Box>
			{!!isOpen && (
				<DeleteDialog
					filename={documentSelected.document_repository_name}
					coinfirmDelete={removeDocument}
					isOpenDialog={isOpen}
					onCancel={onCloseModal}
				/>
			)}
			{!!isOpenCreateModal && (
				<CreateRenameModal
					onOpen={isOpenCreateModal}
					type={'Folder'}
					name={''}
					onSubmit={onCreateNewFolder}
					onClose={onCloseModal}
				/>
			)}
			{!!isOpenEditModal && (
				<CreateRenameModal
					onOpen={isOpenEditModal}
					type={'Folder'}
					name={documentSelected.document_repository_name}
					onSubmit={onRename}
					onClose={onCloseModal}
				/>
			)}
			{!!isOpenShareModal && (
				<SharedModal
					accessableRepositories={accessableRepositories}
					activity={documentSelected}
					access={
						access === DOCS_ACCESS_TYPES.SUPER_ADMIN
							? access
							: !!documentSelected.asset_participant_access_name
							? documentSelected.asset_participant_access_name.toUpperCase()
							: access
					}
					// creator_asset_id={documentSelected.creator_asset_id}
					isOpen={isOpenShareModal}
					onClose={onCloseModal}
				/>
			)}
		</>
	)
}

export default FolderView
