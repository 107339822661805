import { Box, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { IoMdShareAlt } from 'react-icons/io'
import { MdDelete, MdEdit, MdMoreVert } from 'react-icons/md'
import CustomMenu from '../../../components/menu'
import { useLanguageContext } from '../../../context/locale.context'
import {
	// dataLayerTagManager,
	DOCS_ACCESS_TYPES,
	// GA_CATEGORY_REPOSITORY,
	// sectionDetailedTrack,
} from '../repo.utils'

const Folder = ({
	folderData,
	onSelect,
	icon,
	onDelete,
	onRename,
	onShare,
	access,
	isSearch,
	breadCrumbPath = [],
}) => {
	const {
		state: { locale },
	} = useLanguageContext()
	// console.log(['breadCrumbPath', breadCrumbPath])
	//   const [isOpen, setIsOpen] = useState(isOpenDialog);
	const disable = ![
		DOCS_ACCESS_TYPES.ADMIN,
		DOCS_ACCESS_TYPES.SUPER_ADMIN,
	].includes(access)

	return (
		<HStack
			key={-1}
			alignItems='center'
			justifyContent='center'
			cursor='pointer'
			my={2}
			borderRadius='md'
			boxShadow='md'
			w='full'
			p={2}
			maxW='250px'
		>
			<VStack
				flexDir='row'
				flex={1}
				alignItems='flex-start'
				spacing={2}
				onClick={() => {
					// sectionDetailedTrack({
					//   category: GA_CATEGORY_REPOSITORY,
					//   action: 'Folder',
					//   label: 'Select Folder',
					// })
					onSelect(folderData)
				}}
			>
				<VStack justifyContent='center'>
					<Box
						rounded='md'
						size='md'
						color={localStorage.getItem('color')}
						bg='secondary'
						p={2}
					>
						<Icon as={icon} h={6} w={6} />
					</Box>
				</VStack>
				<VStack
					mx={2}
					alignItems='flex-start'
					justifyContent='flex-start'
					w='full'
				>
					<Text fontWeight={500} my={0} fontSize='md' mx={2}>
						{folderData.document_repository_name}
					</Text>
				</VStack>
			</VStack>
			<VStack justifyContent='flex-start'>
				<CustomMenu
					icon={MdMoreVert}
					bg='white'
					borderRadius='0'
					boxShadow='0'
					color='black.200'
					aria-label='More'
					size='sm'
					_focus={{
						bg: 'secondary',
					}}
					_hover={{
						bg: 'secondary',
					}}
					optionList={[
						{
							label: locale['Share'],
							isMenu: true,
							icon: <Icon as={IoMdShareAlt} w={5} h={5} color='brand.900' />,
							onClick: e => {
								// sectionDetailedTrack({
								//   category: GA_CATEGORY_REPOSITORY,
								//   action: 'Folder',
								//   label: 'Share Folder',
								// })
								// dataLayerTagManager('button_click', {
								//   viewName: 'Document Repository',
								//   buttonName: 'Share Folder',
								// })
								onShare(folderData)
							},
							disabled: breadCrumbPath.length <= 1 ? disable : true,
						},
						{
							label: locale['Rename'],
							isMenu: true,
							icon: <Icon as={MdEdit} w={5} h={5} color='brand.900' />,
							onClick: e => {
								// sectionDetailedTrack({
								//   category: GA_CATEGORY_REPOSITORY,
								//   action: 'Folder',
								//   label: 'Rename Folder',
								// })
								// dataLayerTagManager('button_click', {
								//   viewName: 'Document Repository',
								//   buttonName: 'Rename Folder',
								// })
								onRename(folderData)
							},
							disabled: disable,
						},
						{
							label: locale['Delete'],
							isMenu: true,
							icon: <Icon as={MdDelete} w={5} h={5} color='brand.900' />,
							onClick: e => {
								// sectionDetailedTrack({
								//   category: GA_CATEGORY_REPOSITORY,
								//   action: 'Folder',
								//   label: 'Delete Folder',
								// })
								// dataLayerTagManager('button_click', {
								//   viewName: 'Document Repository',
								//   buttonName: 'Delete Folder',
								// })
								onDelete(folderData)
							},
							disabled: disable,
						},
					]}
					selected={{}}
				/>
			</VStack>
		</HStack>
	)
}
export default Folder
