import {
	Avatar,
	Box,
	Center,
	Flex,
	Icon,
	Link,
	Text,
	VStack,
	HStack,
	IconButton,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	isSearch,
	Tooltip,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { BsFileEarmark } from 'react-icons/bs'
import { BiHistory } from 'react-icons/bi'
import { MdDelete, MdFileDownload, MdMoreVert, MdPreview } from 'react-icons/md'
import {
	// dataLayerTagManager,
	DOCS_ACCESS_TYPES,
	// GA_CATEGORY_REPOSITORY,
	// sectionDetailedTrack,
} from '../repo.utils'
import { downloadAwsFile } from '../../../utils/aws.util'
import { configureAmplify } from '../../../utils/auth.util'
import { useLanguageContext } from '../../../context/locale.context'
import { useAuthContext } from '../../../context/auth.context'
const Files = ({
	docTypes,
	folderData,
	onDelete,
	onDownload,
	access,
	isSearch,
	breadCrumbPath = [],
	onPreviewFile,
	onViewFileHistory,
}) => {
	const {
		state: { locale },
	} = useLanguageContext()
	const {
		state: {
			authData: { organization_flag_doc_repo_preview_enabled },
		},
	} = useAuthContext()

	let extension = folderData.document_repository_name.split('.').pop()
	extension = extension.length > 4 ? 'FILE' : extension

	const downloadFile = folderData => {
		downloadAwsFile(folderData.document_repository_folder_url)
		// onDownload(folderData.document_repository_folder_url);
	}
	const disable = ![
		DOCS_ACCESS_TYPES.ADMIN,
		DOCS_ACCESS_TYPES.SUPER_ADMIN,
	].includes(access)
	return (
		<HStack
			justifyContent='space-around'
			alignItems='center'
			bg='secondary'
			borderRadius='lg'
			mx={2}
			p={4}
			position='relative'
		>
			<VStack>
				<Flex
					justifyContent='center'
					align='center'
					h='90px'
					w='90px'
					bg='white'
					borderRadius='lg'
				>
					<Center position='relative'>
						<Box position='absolute' left='0' px={2} bg='white'>
							<Text fontSize='12px' textTransform='uppercase'>
								{' '}
								{extension}{' '}
							</Text>
						</Box>
						<Icon as={BsFileEarmark} color='brand.900' h='4rem' w='4rem' />
					</Center>
				</Flex>
				<Text textAlign='center' fontSize='sm'>{`${
					folderData.document_repository_name.split('.')[0]
				}`}</Text>
			</VStack>
			<Box position='absolute' top='0px' right='5px'>
				<Menu>
					<Tooltip
						hasArrow
						placement='top'
						label={'File Options'}
						aria-label='A tooltip'
					>
						<MenuButton
							as={IconButton}
							aria-label='Options'
							icon={<MdMoreVert />}
							bg='none'
							size='sm'
							borderRadius='md'
							// boxShadow='md'
							_focus={{
								bg: 'secondary',
							}}
							_hover={{
								bg: 'secondary',
							}}
							onClick={e => e.stopPropagation()}
						/>
					</Tooltip>
					<MenuList>
						{[1, 2].includes(organization_flag_doc_repo_preview_enabled) && (
							<MenuItem
								icon={
									<Icon
										as={MdPreview}
										w={5}
										h={5}
										border='brand.900'
										color='brand.900'
									/>
								}
								onClick={e => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_REPOSITORY,
									// 	action: 'Files',
									// 	label: 'Preview',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Document Repository',
									// 	buttonName: 'Preview File',
									// })
									e.stopPropagation()
									onPreviewFile(folderData)
								}}
							>
								{['Preview']}
							</MenuItem>
						)}
						{[0, 2].includes(organization_flag_doc_repo_preview_enabled) && (
							<MenuItem
								icon={
									<Icon
										as={MdFileDownload}
										w={5}
										h={5}
										border='brand.900'
										color='brand.900'
									/>
								}
								onClick={e => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_REPOSITORY,
									// 	action: 'Files',
									// 	label: 'Download',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Document Repository',
									// 	buttonName: 'Download File',
									// })
									e.stopPropagation()
									downloadFile(folderData)
								}}
							>
								{locale['Download']}
							</MenuItem>
						)}
						{!isSearch ? (
							<MenuItem
								icon={
									<Icon
										as={MdDelete}
										w={5}
										h={5}
										border='brand.900'
										color='brand.900'
									/>
								}
								onClick={e => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_REPOSITORY,
									// 	action: 'Files',
									// 	label: 'Delete',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Document Repository',
									// 	buttonName: 'Delete File',
									// })
									e.stopPropagation()
									onDelete(folderData)
								}}
								isDisabled={disable}
							>
								{locale['Delete']}
							</MenuItem>
						) : null}
					</MenuList>
				</Menu>
				{['pdf', 'png', 'jpg', 'jpeg'].includes(extension.toLowerCase()) &&
					[1, 2].includes(organization_flag_doc_repo_preview_enabled) && (
						<Tooltip
							hasArrow
							placement='top'
							label={'File History'}
							aria-label='A tooltip'
						>
							<IconButton
								className={`field-item-tick-icon-button`}
								bg='none'
								aria-label='close'
								size='sm'
								borderRadius='md'
								// boxShadow='md'
								_focus={{
									bg: 'secondary',
								}}
								_hover={{
									bg: 'secondary',
								}}
								icon={<Icon as={BiHistory} w={4} h={4} />}
								onClick={() => onViewFileHistory(folderData)}
							/>
						</Tooltip>
					)}
			</Box>
		</HStack>
	)
}
export default Files
