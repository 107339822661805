/* eslint-disable react-hooks/exhaustive-deps */
import {
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { debounce } from 'lodash';
import React, { useCallback, useState, useEffect } from 'react';
import { MdSearch, MdSort } from 'react-icons/md';
import ActiveParticipantList from './activeParticipants';
import NewParticipantList from './newParticipants';

import CustomMenu from '../../../../components/menu';
export const TeamFilterList = [
  {
    label: 'Users',
    key: 'users',
    value: 1,
  },
  {
    label: 'Roles',
    key: 'roles',
    value: 2,
  },
  {
    label: 'Workforce',
    key: 'workforce',
    value: 3,
  },
];

const SharedView = ({ activity, isOpen, onClose, accessableRepositories }) => {
  const [isReload, setIsReload] = useState(false);
  const [searchQuery, setsearchQuery] = useState('');
  const [searchString, setSearchString] = useState('');
  const [activeParticipants, setActiveParticipants] = useState([]);
  const [searchType, setSearchType] = useState(TeamFilterList[0]);

  const onDebounceSearch = useCallback(
    debounce(value => {
      setsearchQuery(value);
    }, 400)
  );
  useEffect(() => {
    setSearchString('');
  }, [searchType]);
  const onChangeSearchString = value => {
    setSearchString(value);
    onDebounceSearch(value);
  };
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius="md"
        bg="white"
        minW="70vw"
        h="calc(100vh - 150px)"
      >
        <ModalCloseButton zIndex="100" />
        <ModalBody p={0} w="100%" position="relative">
          <HStack
            mt={2}
            position="absolute"
            top="10px"
            minW="calc(70vw - 100px)"
            w="100%"
            mx="4rem"
            px={5}
          >
            <InputGroup mx={5} w="60%">
              <Input
                bg="white"
                placeholder={`Search ${searchType.key}`}
                borderRadius="md"
                variant="filled"
                boxShadow="md"
                value={searchString}
                onChange={e => onChangeSearchString(e.target.value)}
                _focus={{
                  border: 'none',
                }}
              />
              <InputRightElement
                children={<Icon as={MdSearch} color={localStorage.getItem('color')} w={6} h={6} />}
              />
            </InputGroup>
            <CustomMenu
              optionList={TeamFilterList}
              isFilter={true}
              selected={searchType}
              onSelect={item => {
                setSearchType(item);
              }}
              icon={MdSort}
            />
          </HStack>
          <HStack h="full" mt="50px" alignItems="flex-start">
            <ActiveParticipantList
              isReload={isReload}
              onSetReload={setIsReload}
              activity={activity}
              search_string={searchQuery}
              setActiveParticipants={setActiveParticipants}
            />
            <NewParticipantList
              accessableRepositories={accessableRepositories}
              searchType={searchType}
              activity={activity}
              search_string={searchQuery}
              activeParticipants={activeParticipants}
              isReload={isReload}
              // onShare={addParticipants}
              onSetReload={setIsReload}
            />
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SharedView;
