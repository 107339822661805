import React, { useState } from 'react'
import {
	Box,
	Icon,
	Text,
	Heading,
	Divider,
	SimpleGrid,
	VStack,
	Tooltip,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Center,
	Spinner,
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/hooks'
import DeleteDialog from '../../../components/delete-dialog'
import { BsFilePlus } from 'react-icons/bs'
import {
	useRemoveDocument,
	useCreateFolder,
	useRenameFolder,
	useDocRepoPreviewInsert,
} from '../../../hooks/documents.hooks'
import {
	createDocumentPath,
	// dataLayerTagManager,
	DOCS_ACCESS_TYPES,
	// GA_CATEGORY_REPOSITORY,
	// sectionDetailedTrack,
} from '../repo.utils'
import { getLocalAuthDetails } from '../../../utils/common.util'
import AttachFile from '../../../components/upload-modal'
import Files from './files'
import {
	pushFileToS3,
	downloadAwsFile,
	previewAwsFile,
} from '../../../utils/aws.util'
import { useAuthContext } from '../../../context/auth.context'
import moment from 'moment'
import FilePreviewModal from '../../../components/file-preview-history'
import NoPreviewAlert from '../../../components/file-preview-history/no-preview-alert'
// import {useAddFilesToRepository} from '../../../hooks/documents.hooks'

export const FilesView = ({
	docTypes,
	title,
	icon,
	// onSelect,
	refresh,
	parent,
	access,
	isSearch,
	breadCrumbPath,
}) => {
	const {
		state: { authData },
	} = useAuthContext()
	const {
		isOpen: isOpenPreview,
		onOpen: onOpenPreview,
		onClose: onClosePreview,
	} = useDisclosure()
	const {
		isOpen: isAllowOpenPreview,
		onOpen: onAllowOpenPreview,
		onClose: onAllowClosePreview,
	} = useDisclosure()
	const {
		isOpen: isOpenHistory,
		onOpen: onOpenHistory,
		onClose: onCloseHistory,
	} = useDisclosure()
	const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
	const [isOpenEditModal, setIsOpenEditModal] = useState(false)
	const [file_name, set_file_name] = useState('')
	const [previewData, setPreviewData] = useState({})
	const [isOpen, setIsOpen] = useState(false)
	const [documentSelected, setDocumentSelected] = useState('')
	const [previewDoc, setPreviewDoc] = useState({})

	const { mutate: removeDocumentMutate } = useRemoveDocument()
	const { mutate: createFolderMutate } = useCreateFolder()
	const { mutate: renameFolderMutate } = useRenameFolder()
	const { mutate: previewInsert } = useDocRepoPreviewInsert()

	const enableAccess = [
		// DOCS_ACCESS_TYPES.ADMIN,
		DOCS_ACCESS_TYPES.SUPER_ADMIN,
	].includes(access)

	// const {mutate:addFileMutate}=useAddFilesToRepository();
	const deleteDocument = payload => {
		removeDocumentMutate(payload, {
			onSuccess: async res => {
				const {
					data: { status },
				} = res
				if (status === 200) {
					refresh()
				}
			},
			onError: async err => {
				console.log(err)
			},
		})
	}
	const removeDocument = document => {
		const { document_repository_id } = documentSelected
		if (!!isOpen) {
			setIsOpen(false)
			deleteDocument({ document_repository_id: document_repository_id })
		}
	}

	const addFileToFolder = (fileName, fileList) => {
		const { activity_type_id, activity_type_name } = parent
		const params = {
			document_repository_name: fileName || 'Filename',
			document_repository_sub_type_id: 1,
			document_repository_sub_type_name: 'File',
			document_repository_folder_url: JSON.stringify(fileList), //url,
			parent_document_repository_id: parent.document_repository_id,
			tag_id: 0,
			activity_type_id,
			activity_type_name,
		}
		createFolderMutate(params, {
			onSuccess: async res => {
				const {
					data: { status, response },
				} = res
				if (status === 200) {
					console.log('file uploaded', response)
					refresh()
					// (response);
				}
			},
		})
	}

	const onUploadFiles = async files => {
		const filePath = parent.document_repository_folder_url
			? parent.document_repository_folder_url
			: `${authData.organization_id}/${parent.activity_type_name
					.replaceAll(' ', '_')
					.toLowerCase()}/`
		let urlsData = files.map(async sfile => {
			const file = sfile
			const blob = file.slice(0, file.size, file.type)
			const newFile = new File([blob], file.name.replace(/ /gi, '-'), {
				type: blob.type,
			})
			let result = await pushFileToS3(newFile, filePath)
			return {
				url: result.url,
				status: 1,
				fileName: file.name.replace(/ /gi, '-'),
			}
		})
		Promise.all(urlsData)
			.then(urls => {
				let fileList = urls
					.filter(f => f.status === 1)
					.map(u => {
						return { url: u.url, name: u.fileName }
					})
				addFileToFolder(null, fileList)
				onCloseModal()
			})
			.catch(err => {
				onCloseModal()
				console.log(err)
			})
	}
	const onDelete = docs => {
		setDocumentSelected(docs)
		setIsOpen(true)
	}
	const cbDownload = docs => {
		setDocumentSelected(docs)
		downloadAwsFile(docs)
	}

	const onViewFileHistory = doc => {
		setPreviewDoc({
			...doc,
			preview_time: new Date(),
		})
		onOpenHistory()
	}

	const previewFile = doc => {
		let extension = doc.document_repository_folder_url.split('.').pop()
		if (['pdf', 'png', 'jpg', 'jpeg'].includes(extension.toLowerCase())) {
			onOpenPreview()
			setPreviewData({})
			previewAwsFile(doc.document_repository_folder_url, d => {
				setPreviewData(d)
				set_file_name(d.filename)
				setPreviewDoc({
					...doc,
					preview_time: new Date(),
				})
			})
		} else {
			onAllowOpenPreview()
		}
	}

	const onClosePreviewModal = () => {
		onClosePreview()
		const params = {
			...previewDoc,
			target_asset_id: authData.asset_id,
			preview_start_datetime: moment(previewDoc.preview_time).format(
				'YYYY-MM-DDTHH:mm:ss'
			),
			preview_end_datetime: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
			preview_duration: moment(new Date()).diff(
				moment(previewDoc.preview_time),
				'seconds'
			),
		}
		previewInsert(params, {
			onSuccess: async res => {
				console.log({ res })
			},
			onError: async err => {
				console.log(err)
			},
		})
	}

	const onRename = docname => {
		if (documentSelected.document_repository_name !== docname) {
			const { document_repository_id } = documentSelected
			const params = {
				document_repository_name: docname,
				document_repository_id: document_repository_id,
			}
			renameFolderMutate(params, {
				onSuccess: async res => {
					const {
						data: { status },
					} = res
					if (status === 200) {
						refresh()
					}
				},
				onError: async err => {
					console.log(err)
				},
			})
		}
		onCloseModal()
	}
	const onShare = docs => {
		setDocumentSelected(docs)
	}
	const onCloseModal = () => {
		setIsOpenCreateModal(false)
		setIsOpenEditModal(false)
		setIsOpen(false)
		setDocumentSelected('')
	}
	const isShowNewFile =
		!isSearch &&
		!!breadCrumbPath &&
		breadCrumbPath.length !== 1 &&
		(!!(
			parent.asset_participant_access_id === 2 ||
			parent.activity_type_access_level_id === 2 ||
			enableAccess ||
			authData?.asset_id === breadCrumbPath[1].value.creator_asset_id ||
			breadCrumbPath[1].value.activity_creator_asset_id
		) ||
			(breadCrumbPath.length === 3 &&
				(breadCrumbPath[1].value.asset_participant_access_id === 2 ||
					enableAccess ||
					authData?.asset_id === breadCrumbPath[1].value.creator_asset_id ||
					breadCrumbPath[1].value.activity_creator_asset_id) &&
				parent.asset_participant_access_id === undefined))
			? true
			: false
	let access_check =
		!!breadCrumbPath[breadCrumbPath.length - 1] &&
		!!breadCrumbPath[breadCrumbPath.length - 1].value &&
		!!breadCrumbPath[breadCrumbPath.length - 1].value
			.asset_participant_access_name
			? breadCrumbPath[
					breadCrumbPath.length - 1
			  ].value.asset_participant_access_name.toUpperCase()
			: access

	return (
		<>
			<Box px={2}>
				{docTypes.length > 0 ||
				(!isSearch && !!breadCrumbPath && breadCrumbPath.length > 1) ? (
					<>
						<Heading fontSize='md' fontWeight='500' textTransform='uppercase'>
							{title}
						</Heading>
						<Divider my={2} />
					</>
				) : null}

				<SimpleGrid columns={4} spacing={10}>
					{docTypes.map((folderData, index) => {
						return (
							<Files
								key={Math.random() * Date.now()}
								folderData={folderData}
								// onSelect={onSelect}
								onDelete={onDelete}
								onDownload={cbDownload}
								onPreviewFile={previewFile}
								onViewFileHistory={onViewFileHistory}
								icon={icon}
								isSearch={isSearch}
								access={
									access === DOCS_ACCESS_TYPES.SUPER_ADMIN
										? 'SUPER_ADMIN'
										: authData?.asset_id ===
										  (folderData.creator_asset_id ||
												folderData.activity_creator_asset_id)
										? 'ADMIN'
										: !!folderData && !!folderData.asset_participant_access_name
										? folderData.asset_participant_access_name.toUpperCase()
										: access_check
								}
								breadCrumbPath={breadCrumbPath}
							/>
						)
					})}
					{!isSearch && !!breadCrumbPath && breadCrumbPath.length > 1 ? (
						<Tooltip isDisabled={isShowNewFile} label='You have no access!'>
							<VStack
								justifyContent='space-around'
								alignItems='center'
								bg='secondary'
								borderRadius='md'
								boxShadow='md'
								cursor={isShowNewFile ? 'pointer' : 'not-allowed'}
								isDisabled={isShowNewFile}
								mx={1}
								p={3}
								onClick={() => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_REPOSITORY,
									// 	action: 'Files View',
									// 	label: 'New File',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Document Repository',
									// 	buttonName: 'New File',
									// })
									isShowNewFile && setIsOpenCreateModal(true)
								}}
							>
								<Icon
									color={localStorage.getItem('color')}
									as={BsFilePlus}
									w={16}
									h={16}
								/>
								<Text textAlign='center' size='md' p={2} flex={1}>
									New File
								</Text>
							</VStack>
						</Tooltip>
					) : (
						''
					)}
				</SimpleGrid>
			</Box>
			{!!isOpen && (
				<DeleteDialog
					filename={documentSelected.document_repository_name}
					coinfirmDelete={removeDocument}
					isOpenDialog={isOpen}
					onCancel={onCloseModal}
				/>
			)}
			{!!isOpenCreateModal && (
				<AttachFile
					isOpen={isOpenCreateModal}
					onClose={onCloseModal}
					onUpload={onUploadFiles}
				/>
			)}
			{!!isOpenHistory && (
				<FilePreviewModal
					doc={previewDoc}
					isOpen={isOpenHistory}
					onClose={onCloseHistory}
				/>
			)}
			{!!isAllowOpenPreview && (
				<NoPreviewAlert
					isOpen={isAllowOpenPreview}
					onClose={onAllowClosePreview}
				/>
			)}
			<Drawer
				className={`form-edit-view-drawer ${isOpenPreview ? 'expanded' : ''}`}
				isOpen={isOpenPreview}
				placement='right'
				onClose={onClosePreviewModal}
				size='xl'
			>
				<DrawerOverlay className={`form-edit-drawer-overlay`} />
				<DrawerContent className={`form-edit-drawer-content`}>
					<DrawerCloseButton className={`form-edit-drawer-close-button`} />
					<DrawerHeader
						boxShadow='sm'
						bg='secondary'
						className={`form-edit-drawer-header`}
					>
						<VStack alignItems='flex-start' flex={1}>
							<Text
								className={`form-edit-drawer-text`}
								flex={1}
								fontSize='md'
								fontWeight='500'
								textAlign='center'
								textTransform='capitalize'
							>
								{'Preview File Data'}
							</Text>
							<Divider />
							<Text
								flex={1}
								fontSize='md'
								fontWeight='500'
								textAlign='center'
								textTransform='capitalize'
							>
								{file_name}
							</Text>
						</VStack>
					</DrawerHeader>
					<DrawerBody className={`form-edit-drawer-body`} height='full'>
						{!!previewData?.data_url ? (
							<iframe
								title='doc-iframe'
								src={previewData?.data_url}
								height='100%'
								width='100%'
							/>
						) : (
							<Center p='10'>
								<Spinner size='lg' color='brand.900' />
							</Center>
						)}
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	)
}

export default FilesView
