/* eslint-disable react-hooks/exhaustive-deps */
import {
	Avatar,
	Box,
	Button,
	Center,
	Divider,
	Flex,
	Heading,
	List,
	ListItem,
	Text,
	VStack,
	Checkbox,
	Select,
	useDisclosure,
	useToast,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import AlertBox from '../../../../../components/AlertBox'
import {
	GA_CATEGORY_REPOSITORY,
	sectionDetailedTrack,
	USER_SEARCH_TYPES,
} from '../../../repo.utils'
import {
	useSearchRoleContacts,
	useSearchOrgContacts,
	useSearchWorkforceContacts,
	useSetWorkforceRoleAccess,
	useShareRepository,
} from '../../../../../hooks/documents.hooks'
import { sharedConst } from '../../../../../utils/action.constant'
import { useSharedContext } from '../../../../../context/shared.context'
const NewParticipantList = ({
	accessableRepositories,
	activity,
	isReload,
	activeParticipants,
	search_string,
	onSetReload,
	searchType,
	// onShare,
}) => {
	const toast = useToast({
		duration: 1000,
		position: 'top',
	})
	// const { activity_id, activity_type_id } = activity;
	const { mutate: searchOrgContactMutate } = useSearchOrgContacts()
	const { mutate: searchRoleContactMutate } = useSearchRoleContacts()
	const { mutate: searchWorkforceContactMutate } = useSearchWorkforceContacts()
	const { mutate: setWorkForceRoleAccessMutate } = useSetWorkforceRoleAccess()
	const { mutate: shareRepositoryMutate } = useShareRepository()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [data, setData] = useState([])
	const [checkedUsers, setCheckedUsers] = useState([])
	const [checkedAssetTypeIds, setCheckedAssetTypeIds] = useState([])
	const [selectedAccess, setSelectedAccess] = useState(3)
	const [allChecked, setAllChecked] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [srhType, setSrhType] = useState('')
	const { state, dispatch } = useSharedContext()
	const [pageCount, setPageCount] = useState(0)
	const { isRefreshDocRepo } = state || {}

	const loadOrgContacts = useCallback((search_string, start_from) => {
		searchOrgContactMutate(
			{ search_string, start_from },
			{
				onSuccess: async data => {
					setData(data)
					onSetReload(false)
				},
			}
		)
	})

	useEffect(() => {
		setData([])
		setCheckedUsers([])
		setCheckedAssetTypeIds([])
		setAllChecked(false)
	}, [searchType])

	const loadRoleContacts = useCallback((search_string, start_from) => {
		searchRoleContactMutate(
			{ search_string, start_from },
			{
				onSuccess: async data => {
					if (!!data && data.length > 0) {
						// console.log(['role', data]);
						let roleList = data.filter(f => {
							return f.asset_name
								.toLowerCase()
								.includes(search_string.toLowerCase())
						})
						setData(roleList)
						onSetReload(false)
					}
				},
			}
		)
	})
	const loadWorkforceContacts = useCallback(search_string => {
		searchWorkforceContactMutate(
			{ search_string },
			{
				onSuccess: async data => {
					let users = data.filter(f => {
						return f.asset_name
							.toUpperCase()
							.includes(search_string.toUpperCase())
					})
					setData(users)
					onSetReload(false)
				},
			}
		)
	})
	const setWorkforceRoleAccess = useCallback(params => {
		setWorkForceRoleAccessMutate(params, {
			onSuccess: async res => {
				const {
					data: { status },
				} = res
				if (status === 200) {
					console.log('workforce role access set')
				}
			},
		})
	})
	const shareRepositories = useCallback(params => {
		shareRepositoryMutate(params, {
			onSuccess: async res => {
				const {
					data: { status, response },
				} = res
				if (status === 200) {
					onSearchUser('s', {})
					onSetReload(true)
					toast({
						title: 'Repository Shared successfully!',
						status: 'success',
					})
					setCheckedAssetTypeIds([])
					setCheckedUsers([])
				} else {
					setAlertMessage('Sharing Failed')
				}
			},
		})
	})

	let collaboratorAssetId = activeParticipants.map(c => c.asset_id)

	useEffect(() => {
		if (!!search_string) {
			onSearchUser(search_string, {
				userpageStartVal: 0,
				rolepageStartVal: 0,
				workForceStaerVal: 0,
			})
			// setCheckedUsers([])
			// setCheckedAssetTypeIds([])
			// setAllChecked(false)
		}
		if (!!isReload) {
			onSearchUser(search_string, {})
			// setCheckedUsers([])
			// setCheckedAssetTypeIds([])
			// setAllChecked(false)
		}
	}, [search_string, isRefreshDocRepo, isReload])

	const onSearchUser = (search_string, pageObj) => {
		if (searchType.value === USER_SEARCH_TYPES.ORG) {
			///do something
			loadOrgContacts(search_string, pageObj?.userpageStartVal || 0)
		} else if (searchType.value === USER_SEARCH_TYPES.ROLE) {
			////do something
			loadRoleContacts(search_string, pageObj?.rolepageStartVal || 0)
		} else {
			loadWorkforceContacts(search_string, pageObj?.workForceStaerVal || 0)
			/// do something
		}
	}

	const onShare = () => {
		dispatch({
			type: sharedConst.REFRESH_DOC_REPO,
			isRefreshDocRepo: true,
		})
		// console.log(checkedUsers)
		if (!selectedAccess) {
			toast({
				status: 'error',
				title: 'select access level',
			})
			return
		}
		const { document_repository_id, activity_type_id } = activity || {}

		let assetTypes =
			accessableRepositories?.data &&
			accessableRepositories?.data?.map(doc => doc.asset_type_id)
		let assetSelected = checkedAssetTypeIds?.filter(
			a => !assetTypes.includes(a)
		)
		let shareParams = {}
		let workForceParams = {}
		if (searchType.value === USER_SEARCH_TYPES.ROLE) {
			shareParams = {
				document_repository_id,
				target_assets: JSON.stringify([]),
				target_access_id: selectedAccess,
				asset_types: JSON.stringify(checkedUsers),
				target_workforces: JSON.stringify([]),
				access_level_id: 5,
				type_flag: 2, /// roles
			}
			workForceParams = {
				activity_id: 0,
				activity_type_id,
				workforce_array: JSON.stringify([]),
				asset_type_array: JSON.stringify(checkedUsers),
				type_flag: 0, // flag 1 is used workforce_array and flag 0 is used asset_type_array
				activity_type_category_id: 61,
				access_type_id: selectedAccess,
				listFlag: 2,
				document_repository_id,
			}
		} else if (searchType.value === USER_SEARCH_TYPES.ORG) {
			shareParams = {
				document_repository_id,
				target_assets: JSON.stringify(checkedUsers),
				target_access_id: selectedAccess,
				asset_types: JSON.stringify([]),
				target_workforces: JSON.stringify([]),
				access_level_id: 6,
				type_flag: 1, // users
			}
			workForceParams = {
				activity_id: 0,
				activity_type_id,
				workforce_array: JSON.stringify([]),
				asset_type_array: JSON.stringify(assetSelected),
				type_flag: 0, // flag 1 is used workforce_array and flag 0 is used asset_type_array
				activity_type_category_id: 61,
				access_type_id: selectedAccess,
				listFlag: 2,
				document_repository_id,
			}
		} else {
			shareParams = {
				document_repository_id,
				target_workforces: JSON.stringify(checkedUsers),
				target_access_id: selectedAccess,
				asset_types: JSON.stringify([]),
				target_assets: JSON.stringify([]),
				access_level_id: 6,
				type_flag: 3, //for workforce
			}
			workForceParams = {
				activity_id: 0,
				activity_type_id,
				workforce_array: JSON.stringify(checkedUsers),
				asset_type_array: JSON.stringify([]),
				type_flag: 1, // flag 1 is used workforce_array and flag 0 is used asset_type_array
				activity_type_category_id: 61,
				access_type_id: selectedAccess,
				listFlag: 2,
				document_repository_id,
			}
		}
		setWorkforceRoleAccess(workForceParams)
		shareRepositories(shareParams)
		setCheckedUsers([])
		setCheckedAssetTypeIds([])
		setAllChecked(false)
	}

	const usersCheck = e => {
		let chkusers = checkedUsers
		let assetTypeIds = checkedAssetTypeIds
		if (!!e.target.checked) {
			chkusers.push(Number(e.target.value))
			assetTypeIds.push(Number(e.target.name))
		} else {
			let index = chkusers.findIndex(u => Number(u) === Number(e.target.value))
			let index2 = assetTypeIds.findIndex(
				a => Number(a) === Number(e.target.name)
			)
			chkusers.splice(index, 1)
			assetTypeIds.splice(index2, 1)
		}
		if (data.length > 0 && assetTypeIds.length === data.length) {
			setCheckedUsers([...chkusers])
			setCheckedAssetTypeIds([...assetTypeIds])
			setAllChecked(true)
		} else {
			setCheckedUsers([...chkusers])
			setCheckedAssetTypeIds([...assetTypeIds])
			setAllChecked(false)
		}
	}
	const selectAllUsers = e => {
		if (!!e.target.checked) {
			let chkusers = []
			let assetTypeIds = []
			data.forEach(user => {
				chkusers.push(user.asset_id)
				assetTypeIds.push(user.asset_type_id)
			})
			setCheckedUsers(chkusers)
			setCheckedAssetTypeIds(assetTypeIds)
			setAllChecked(true)
		} else {
			setCheckedUsers([])
			setCheckedAssetTypeIds([])
			setAllChecked(false)
		}
	}

	return (
		<VStack h='calc(100vh - 190px)' w='55%' spacing={2} p={4}>
			<Box bg='secondary' w='100%' my={4} py={2} mt='15px' textAlign='center'>
				<Heading
					textTransform='uppercase'
					size='md'
					color='brand.900'
					fontWeight='400'
				>
					Invite New Participants
				</Heading>
			</Box>
			<Box w='full' d='flex'>
				<Select
					onChange={e => {
						setSelectedAccess(e.target.value)
					}}
					value={selectedAccess}
				>
					{searchType.value === 1 ? <option value={2}>Admin</option> : null}
					<option value={3}>View</option>
				</Select>
				<Button
					size='md'
					w={40}
					ml={2}
					colorScheme={localStorage.getItem('color')}
					bg={localStorage.getItem('color')}
					onClick={() => {
						sectionDetailedTrack({
							category: GA_CATEGORY_REPOSITORY,
							action: 'New Participant List',
							label: 'Add',
						})
						onShare(checkedUsers, checkedAssetTypeIds, selectedAccess)
						setPageCount(val => val + 10)
						setSelectedAccess(3)
					}}
					isDisabled={checkedUsers.length === 0}
				>
					Add
				</Button>
			</Box>
			{data.length > 0 && (
				<Box w='full' style={{ direction: 'rtl' }} d='flex'>
					<Checkbox
						isChecked={allChecked}
						isIndeterminate={
							data.length > 0 &&
							!allChecked &&
							checkedUsers.length > 0 &&
							data.length > checkedUsers.length
								? true
								: false
						}
						onChange={e => selectAllUsers(e)}
						value={!allChecked}
					>
						Select All ({checkedUsers.length})
					</Checkbox>
				</Box>
			)}
			<List px={5} w='full' spacing={3} h='550px' overflowY='scroll'>
				{/* <ListItem>

        </ListItem> */}

				{
					// srhType!==1 &&search_string===''?<ListItem></ListItem>:
					data &&
						(data || [])
							.filter(u => !collaboratorAssetId.includes(u.asset_id))
							.map(user => {
								const {
									asset_name: fname,
									asset_country_code: cCode,
									asset_phone: pCode,
									// account_name,
									asset_type_name,
								} = user
								return (
									<ListItem
										key={Math.random() * Date.now()}
										p={2}
										bg='secondary'
									>
										<Flex spacing={2}>
											<Center position='relative' alignSelf='center'>
												<Avatar
													name={fname}
													bg='white'
													color='black'
													boxShadow='md'
													size='md'
												/>
											</Center>
											<Box ml={2} flex={1}>
												<Text
													color='brand.800'
													fontSize='14px'
													textTransform='capitalize'
												>
													{fname}
												</Text>
												{user.document_access_level_id === 6 && (
													<Text color='brand.800' fontSize='12px'>
														{`+${cCode} ${pCode}`} | {asset_type_name} |
													</Text>
												)}
												{user.document_access_level_id === 3 && (
													<Text color='brand.800' fontSize='12px'>
														Account Name : {user.sub_title} |
													</Text>
												)}
												{user.document_access_level_id === 5 && (
													<Text color='brand.800' fontSize='12px'>
														Role
													</Text>
												)}
											</Box>
											<Checkbox
												size='md'
												color={localStorage.getItem('color')}
												name={user.asset_type_id}
												value={user.asset_id}
												isChecked={checkedUsers.includes(user.asset_id)}
												onChange={e => usersCheck(e)}
											/>
										</Flex>
									</ListItem>
								)
							})
				}
			</List>
			<AlertBox isOpen={isOpen} onClose={onClose} Message={alertMessage} />
		</VStack>
	)
}

export default NewParticipantList
