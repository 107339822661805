/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Badge,
  Box,
  Center,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
  Icon,
  useToast,
  VStack,
  IconButton,
  Skeleton,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { MdDelete } from 'react-icons/md'
import { useAuthContext } from '../../../../../context/auth.context'
import { useSharedContext } from '../../../../../context/shared.context'
import {
  useGetSharedUsreList,
  useRevokeShare,
} from '../../../../../hooks/documents.hooks'
import {
  GA_CATEGORY_REPOSITORY,
  sectionDetailedTrack,
} from '../../../repo.utils'

const ActiveParticipantList = ({
  activity,
  isReload,
  onSetReload,
  setActiveParticipants,
}) => {
  // const [data, setdata] = useState([]);
  const [sharedUsers, setSharesUsers] = useState([])
  const toast = useToast({
    duration: 1000,
    position: 'top',
  })
  const { state, dispatch } = useSharedContext()
  const { isRefreshDocRepo } = state || {}

  const {
    state: { authData },
  } = useAuthContext()

  const { mutate, isLoading } = useGetSharedUsreList()
  const { mutate: removeMutate } = useRevokeShare()
  const [loading, setLoading] = useState(false)
  // const {
  //   activity_id,
  //   activity_master_data,
  //   activity_creator_asset_id: creator,
  //   activity_lead_asset_id: lead,
  // } = activity;
  // const { form_fill_request: requiredList } = activity_master_data
  //   ? JSON.parse(activity_master_data)
  //   : {};

  //   const {
  //   operating_asset_first_name: setterName,
  //   asset_id: loggedInuserId,
  // } = authData;

  // // CHECK WETHER USER IS HAVING REMOVE ACCESS OR NOT
  // const ownerList = data
  //   ? (data.list || [])
  //       .map(u => !!u.asset_flag_is_owner && u.asset_id)
  //       .filter(Boolean)
  //   : [];
  // let canHaveRemoveAccess = [creator, lead, ...ownerList].includes(
  //   loggedInuserId
  // );

  const removeParticipant = useCallback(participant => {
    removeMutate(
      { ...participant },
      {
        onSuccess: async data => {
          onSetReload(true)
          toast({
            title: 'Removed successfully!',
            status: 'success',
          })
        },
        onError: async error => {
          console.log('remove error', error)
        },
      }
    )
  })

  const { document_repository_id, activity_type_id } = activity
  const loadParticipants = useCallback(() => {
    setLoading(true)
    let participantList = []
    mutate(
      {
        document_repository_id,
        activity_type_id,
        repository_sub_type_id: 0,
        page_start: 0,
        page_limit: 45,
      },
      {
        onSuccess: async res => {
          const {
            data: { status, response },
          } = res
          if (status === 200) {
            participantList = participantList.concat(response)
            if (response?.length >= 45) {
              getMoreActiveParticipants(response?.length, participantList)
            } else {
              setActiveParticipants(response)
              setSharesUsers(response)
              onSetReload(false)
              setLoading(false)
            }
          }
          // onSetReload(false);
        },
      }
    )
  })

  const getMoreActiveParticipants = (resLength, participants) => {
    mutate(
      {
        document_repository_id,
        activity_type_id,
        repository_sub_type_id: 0,
        page_start: resLength || 0,
        page_limit: 45,
      },
      {
        onSuccess: async res => {
          const {
            data: { status, response },
          } = res
          if (status === 200) {
            participants = participants.concat(response)
            if (response?.length >= 45) {
              getMoreActiveParticipants(participants?.length, participants)
            } else {
              setActiveParticipants(participants)
              setSharesUsers(participants)
              onSetReload(false)
              setLoading(false)
            }
          }
          // onSetReload(false);
        },
      }
    )
  }

  useEffect(() => {
    loadParticipants()
  }, [isRefreshDocRepo])

  useEffect(() => {
    if (!!isReload) {
      loadParticipants()
      // setReload(false);
    }
  }, [isReload])

  const removeUser = user => {
    const { asset_id, asset_type_id, document_access_level_id } = user || {}
    const { document_repository_id } = activity || {}
    let params = {}
    if (document_access_level_id === 6) {
      //Asset Level
      params = {
        document_repository_id,
        target_asset_id: asset_id,
        asset_type_id: 0,
        target_access_id: 2,
        flag: 1,
      }
    } else if (document_access_level_id === 5) {
      ///ROLE LEVEL
      params = {
        document_repository_id,
        target_asset_id: 0,
        asset_type_id: asset_type_id,
        target_access_id: 2,
        flag: 2,
      }
    } else if (document_access_level_id === 3) {
      ///WORK FORCE LEVEL CHANGES
      params = {
        document_repository_id,
        target_workforce_id: asset_id,
        asset_type_id: 0,
        target_asset_id: 0,
        target_access_id: 2,
        flag: 3,
      }
    }
    removeParticipant(params)
  }

  return (
    <VStack h="calc(100vh - 190px)" w="55%" spacing={2} p={4}>
      <Box bg="secondary" w="100%" my={4} py={2} textAlign="center">
        <Heading
          textTransform="uppercase"
          size="md"
          color="brand.900"
          fontWeight="400"
        >
          Collaborators
        </Heading>
      </Box>
      <List px={5} w="full" spacing={3} h="550px" overflowY="scroll">
        {!loading
          ? sharedUsers?.map(user => {
              return (
                <ListItem key={user.asset_id} p={2} bg="secondary">
                  <Flex spacing={2}>
                    <Center position="relative" alignSelf="center">
                      <Avatar
                        name={user.asset_name}
                        bg="white"
                        color="black"
                        boxShadow="md"
                        size="md"
                      />
                    </Center>
                    <Box p={2} px={6} w="80%">
                      <Text color="black">{user.asset_name}</Text>
                      {user.document_access_level_id === 6 ? (
                        <Text>{`+${user.asset_country_code} ${user.asset_phone} (${user.asset_type_name})`}</Text>
                      ) : user.document_access_level_id === 3 ? (
                        <Text>Account Name : {user.sub_title}</Text>
                      ) : (
                        <Text>Role </Text>
                      )}
                      {user.access_level && (
                        <Badge
                          variant="outline"
                          colorScheme={localStorage.getItem('color')}
                          borderRadius="xl"
                          px={4}
                          py={1}
                        >
                          {user.access_level}
                        </Badge>
                      )}
                    </Box>
                    {user.access_level &&
                      activity.creator_asset_id !== user.asset_id &&
                      user.asset_id !== authData.asset_id && (
                        <IconButton
                          my={2}
                          boxShadow="md"
                          icon={<Icon as={MdDelete} h={6} w={6} />}
                          color={localStorage.getItem('color')}
                          _focus={{
                            color:localStorage.getItem('color'),
                          }}
                          _hover={{
                            color: 'white',
                            bg: localStorage.getItem('color'),
                          }}
                          _active={{
                            color: 'white',
                            bg: localStorage.getItem('color'),
                          }}
                          onClick={() => {
                            sectionDetailedTrack({
                              category: GA_CATEGORY_REPOSITORY,
                              action: 'Active Participant',
                              label: 'Rename User',
                            })
                            removeUser(user)
                          }}
                        />
                      )}
                  </Flex>
                </ListItem>
              )
            })
          : [1, 2, 3, 4, 5, 6, 7, 8].map(i => {
              return (
                <Skeleton
                  boxShadow="md"
                  borderRadius="md"
                  key={i}
                  mb={2}
                  w="full"
                  h="53px"
                />
              )
            })}
      </List>
    </VStack>
  )
}

export default ActiveParticipantList
